import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Wrapper from '../../components/Wrapper/Wrapper';
import Grid from '../../components/Grid/Grid';
import ProductCard from '../../components/Cards/Product/ProductCard';
import { CartContext } from '../../components/CartProvider';
import SEO from '../../components/SEO';

const Products = ({
  data: {
    allStripePrice: { edges: stripeEdges },
    allMarkdownRemark: { edges }
  }
}) => {
  // lookup table
  const prices = {};

  const { currency } = useContext(CartContext);

  for (const { node } of stripeEdges) {
    if (node.currency === currency) {
      prices[node.product.id] = node.unit_amount;
    }
  }

  return (
    <Wrapper>
      <SEO />
      <main id="container" className="container">
        <h1 className="title is-1 has-text-centered">Products</h1>
        <Grid cols={3}>
          {edges.map(
            ({
              node: {
                id,
                fields: { slug },
                frontmatter: {
                  images: [first],
                  title,
                  productId
                }
              }
            }) => (
              <ProductCard
                key={id}
                image={first}
                title={title}
                price={prices[productId]}
                currency={currency}
                link={slug}
              />
            )
          )}
        </Grid>
      </main>
    </Wrapper>
  );
};

Products.propTypes = {
  data: PropTypes.shape({
    allStripePrice: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
    }).isRequired,
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
    }).isRequired
  }).isRequired
};

export default Products;

export const query = graphql`
  query ProductsQuery {
    allStripePrice(filter: { active: { eq: true } }) {
      edges {
        node {
          unit_amount
          currency
          product {
            id
          }
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "ProductPage" } } }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            productId
            images {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;
