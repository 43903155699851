import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { size, imgFormatting, productTitle, width } from './ProductCard.module.scss';
import { fmtPrice } from '../../../utils';
import AnimatedCardWrapper from '../../utils/AnimatedCardWrapper';

const ProductCard = ({ image, title, price, currency, link }) => (
  <Link to={link} aria-label="Link to product page">
    <AnimatedCardWrapper className={`card mb-5 ${size}`}>
      <div className="card-image">
        <figure className={`image ${imgFormatting} has-background-white-bis`}>
          <GatsbyImage image={getImage(image)} className={width} imgClassName={width} alt="" />
        </figure>
      </div>
      <div className="card-content">
        <p className={`title is-5 ${productTitle}`}>{title}</p>
        <p className="subtitle is-6 is-italic">{fmtPrice(currency, price)}</p>
      </div>
    </AnimatedCardWrapper>
  </Link>
);

ProductCard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default ProductCard;
